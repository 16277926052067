import React from 'react';

import { NFLGamePreview } from "../../types/api/nfl/NFLGamePreview"
import { NFLTeamNameUtils } from "../../utils/nfl/NFLTeamNameUtils";

import styles from './NFLGamePreviewDetailComponent.module.css';
import { NFLGameSpreadPrediction } from '../../types/api/nfl/NFLGameSpreadPrediction';
import { NFLTeam } from '../../types/api/nfl/NFLTeam';

type NFLGameSpreadSuggestion = {
  team: NFLTeam;
  prediction: NFLGameSpreadPrediction;
}

export type NFLGamePreviewDetailProps = {
  preview: NFLGamePreview;
}

export default function NFLGamePreviewDetail({
  preview,
}: NFLGamePreviewDetailProps) {
  const { homeTeam, awayTeam, date } = preview;
  const dateObj = new Date(date);
  const dateStr = dateObj.toLocaleDateString(undefined, {
    month: 'long', day: 'numeric', year: 'numeric',
  });
  dateObj.setHours(dateObj.getHours() - 1);
  const timeStr = dateObj.toLocaleTimeString(undefined, {
    hour: 'numeric', minute:  'numeric',
  });
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.gameInfo}>
          <div className={styles.teamsTitle}>
            <div className={styles.awayTeam}>{awayTeam.name}</div>
            <div className={styles.teamAt}>at</div>
            <div className={styles.homeTeam}>{homeTeam.name}</div>
          </div>
          <div className={styles.gameTimeInfo}>
            <div className={styles.gameDate}>{dateStr}</div>
            <div className={styles.gameTime}>{timeStr}</div>
          </div>
        </div>
        <div className={styles.suggestionsContainer}>
          <table className={styles.suggestionsTable}>
            <thead>
              <th>Team</th>
              <th>Bookmaker</th>
              <th>Region</th>
              <th>Spread</th>
              <th>Price</th>
              <th>Bet Size</th>
            </thead>
            {renderSuggestionsTableBody(preview)}
          </table>
        </div>
      </div>
    </div>
  )
}

function renderSuggestionsTableBody(preview: NFLGamePreview): JSX.Element {
  const suggestions: NFLGameSpreadSuggestion[] = getSuggestionsFromPreview(preview);
  return (
    <tbody>
      {suggestions.map((suggestion: NFLGameSpreadSuggestion) =>
        renderSuggestionTableRow(suggestion)
      )}
    </tbody>
  );
}

function renderSuggestionTableRow(suggestion: NFLGameSpreadSuggestion): JSX.Element {
  const { team, prediction } = suggestion;
  const { bookmaker, region, spread, price, kellyCriterion } = prediction;
  const spreadStr = spread > 0 ? `+${spread}` : `${spread}`;
  const betSize = kellyCriterion * 100;
  const betSizeStr = betSize > 0 ? `${betSize.toPrecision(2)}%` : '-';
  return (
    <tr>
      <td className={styles.tableTeamCell}>{team.code}</td>
      <td className={styles.tableBookmakerCell}>{bookmaker.name}</td>
      <td className={styles.tableRegionCell}>{region}</td>
      <td className={styles.tableSpreadCell}>{spreadStr}</td>
      <td className={styles.tablePriceCell}>{price}</td>
      <td className={styles.tableBetSizeCell}>{betSizeStr}</td>
    </tr>
  )
}

function getSuggestionsFromPreview(preview: NFLGamePreview): NFLGameSpreadSuggestion[] {
  const { homeTeam, awayTeam, homePredictions, awayPredictions } = preview;
  const homeSuggestions = homePredictions.gameSpread
    .map((prediction) => ({ team: homeTeam, prediction }));
  const awaySuggestions = awayPredictions.gameSpread
    .map((prediction) => ({ team: awayTeam, prediction }));
  return [...homeSuggestions, ...awaySuggestions]
    .sort((a, b) => b.prediction.kellyCriterion - a.prediction.kellyCriterion)
}