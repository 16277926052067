import React from 'react';

import { NFLGamePreview } from "../../types/api/nfl/NFLGamePreview"
import { NFLGameSpreadPrediction } from "../../types/api/nfl/NFLGameSpreadPrediction";
import { NFLTeamNameUtils, TeamName } from '../../utils/nfl/NFLTeamNameUtils';

import styles from './NFLGameSuggestionsComponent.module.css';

export type NFLGamePreviewProps = {
  preview: NFLGamePreview;
  onSelect: (preview: NFLGamePreview) => any;
  region?: string;
}

export default function NFLGameSuggestions({
  preview,
  region,
  onSelect,
}: NFLGamePreviewProps) {
  const { homeTeam, awayTeam, date } = preview;
  const keyStr = `${homeTeam.code}_${awayTeam.code}_${date.toLocaleString()}`;
  return (
    <div className={styles.container} onClick={() => onSelect(preview)} key={keyStr}>
      {renderTeam(preview, region, true)}
      {renderTeam(preview, region, false)}
    </div>
  )
}

function renderTeam(
  preview: NFLGamePreview,
  region: string = 'eu',
  isHomeTeam: boolean,
): JSX.Element {
  const { homeTeam, awayTeam, homePredictions, awayPredictions, date } = preview;
  const className = isHomeTeam ? styles.homeTeam : styles.awayTeam;
  // The first cell displays the team code.
  const team = isHomeTeam ? homeTeam : awayTeam;
  const teamColor = NFLTeamNameUtils.getPrimaryColorFromTeamName(team.code as unknown as TeamName);
  // The second and third cells display the best spread and price available.
  const predictions = isHomeTeam ? homePredictions : awayPredictions;
  const regionPredictions = predictions.gameSpread.filter((prediction) => prediction.region === region);
  let bestSpreadStr, bestPrice, bestKellyPrice, bestKellySpread;
  let bestSpread = Number.MIN_SAFE_INTEGER;
  let bestKellyPctg = Number.MIN_SAFE_INTEGER;
  let isKellyAvailable = false;
  if (regionPredictions.length > 0) {
    regionPredictions.forEach((prediction) => {
      // Keep track of the best spread/price combo.
      if (prediction.spread > bestSpread) {
        bestSpread = prediction.spread;
        bestPrice = prediction.price;
      }
      // Keep track of the best spread/price/Kelly combo, too, which may not be the same.
      if (prediction.kellyCriterion > 0) {
        if (prediction.kellyCriterion > bestKellyPctg) {
          isKellyAvailable = true;
          bestKellyPctg = prediction.kellyCriterion;
          bestKellySpread = prediction.spread;
          bestKellyPrice = prediction.price;
        }
      }
    });
    const bestSpreadValue = isKellyAvailable ? (bestKellySpread ?? 0) : bestSpread;
    bestSpreadStr = bestSpreadValue > 0 ? `+${bestSpreadValue}` : bestSpreadValue;
    bestPrice = isKellyAvailable ? bestKellyPrice : bestPrice;
  }
  const spreadPriceCellStyle = isKellyAvailable ? { backgroundColor: "#addcad" } : {};
  // Add a key to these divs.
  const keyStr = `${team.code}_${date.toLocaleString()}`;
  return (
    <div className={className}>
      <div className={styles.teamNameCell} style={{backgroundColor: `${teamColor}DD`}} key={`${keyStr}_name`}>{team.code}</div>
      <div className={styles.teamSpreadCell} style={spreadPriceCellStyle} key={`${keyStr}_spread`}>{bestSpread !== -1 ? bestSpreadStr : ''}</div>
      <div className={styles.teamPriceCell} style={spreadPriceCellStyle} key={`${keyStr}_price`}>{bestPrice !== -1 ? bestPrice : ''}</div>
    </div>
  );
}