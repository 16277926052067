import React from 'react';

import styles from './FooterComponent.module.css';

export default function Footer() {
  return (
    <div className={styles.container}>
      <div>Scorecasters© 2023</div>
    </div>
  )
}