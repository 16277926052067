import React from 'react';

import styles from './NavBarComponent.module.css';

export default function NavBar() {
  return (
    <div className={styles.container}>
      <a href="/"><div className={styles.logo}>Scorecasters</div></a>
    </div>
  )
}