import { QueryResult } from 'react-apollo';
import gql from 'graphql-tag';

import { NFLGamePreview } from '../../types/api/nfl/NFLGamePreview';

export type GetNFLPreviewsQueryResponse = {
  data: {
    previews: NFLGamePreview[],
  },
} & QueryResult;

export const GetNFLGamePreviewsQuery = gql`
  query GetNFLGamePreviews {
    getNFLGamePreviews {
      previews {
        date,
        seasonYear,
        weekNumber,
        homeTeamElo,
        awayTeamElo,
        eloWinExp,
        homeTeam {
          name,
          code,
        },
        awayTeam {
          name,
          code,
        },
        homePredictions {
          gameSpread {
            bookmaker {
              name,
              code,
            },
            spread,
            price,
            region,
            kellyCriterion,
          }
        },
        awayPredictions {
          gameSpread {
            bookmaker {
              name
            },
            spread,
            price,
            region,
            kellyCriterion
          }
        }
      }
    }
  }
`;