import React from 'react';

import { NFLGamePreview } from '../../types/api/nfl/NFLGamePreview';
import NFLGameSuggestions from './NFLGameSuggestionsComponent';

import styles from './NFLGamePreviewListComponent.module.css'

type DateToPreviewsMap = {
  [date: string]: {
    [time: string]: NFLGamePreview[],
  }
}

export type NFLGamePreviewsListProps = {
  previews: NFLGamePreview[];
  onSelect: (preview: NFLGamePreview) => any;
}

export default function NFLGamePreviewsList({
  previews,
  onSelect,
}: NFLGamePreviewsListProps) {
  // Organize the previews by the date of the game.
  const previewsByDate = previews.reduce(
    (dateMap: DateToPreviewsMap, preview: NFLGamePreview) => {
      const { date } = preview;
      const dateObj = new Date(date);
      // For some reason the stored times of games is off by an hour.
      dateObj.setHours(dateObj.getHours() - 1);
      const dateStr = dateObj.toLocaleDateString(undefined, {
        month: 'long', day: 'numeric', year: 'numeric',
      });
      const time = dateObj.toLocaleTimeString(undefined, {
        hour: 'numeric', minute: 'numeric',
      });
      if (dateMap[dateStr] === undefined) {
        dateMap[dateStr] = {};
      }
      if (dateMap[dateStr][time] === undefined) {
        dateMap[dateStr][time] = [];
      }
      dateMap[dateStr][time].push(preview);
      return dateMap;
    },
    {},
  );
  const dates: string[] = Object.keys(previewsByDate)
    .sort((a, b) => {
      const dateA = new Date(a);
      const dateB = new Date(b);
      return dateA.getTime() - dateB.getTime();
    })
    .filter((dateStr) => {
      const dateObj = new Date(dateStr);
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      if (yesterday.getMonth() > dateObj.getMonth()) {
        return false;
      } else if (dateObj.getMonth() > yesterday.getMonth()) {
        return true;
      }
      return dateObj.getDate() >= yesterday.getDate()
    })

  return (
    <div className={styles.container}>
      {dates.map((date: string) =>
        renderPreviewsForDate(date, previewsByDate, onSelect)
      )}
    </div>
  )
}

function renderPreviewsForDate(date: string, dateToPreviews: DateToPreviewsMap, onSelect: (preview: NFLGamePreview) => any): JSX.Element {
  const keyStr = `${date}`;
  const times = Object.keys(dateToPreviews[date]);
  return (
    <div className={styles.dateSection} key={`${keyStr}_section`}>
      <div className={styles.dateTitle} key={`${keyStr}_title`}>{date}</div>
      <div className={styles.dateContainer} key={keyStr}>
        {times.map((time: string) =>
          renderPreviewsForTime(time, date, dateToPreviews, onSelect)
        )}
      </div>
    </div>
  )
}

function renderPreviewsForTime(time: string, date: string, dateToPreviews: DateToPreviewsMap, onSelect: (preview: NFLGamePreview) => any): JSX.Element {
  const keyStr = `${time}_${date}`;
  return (
    <div className={styles.timeSection} key={`${keyStr}_section`}>
      <div className={styles.timeTitle} key={`${keyStr}_title`}>{time}</div>
      <div className={styles.timeContainer} key={keyStr}>
        {dateToPreviews[date][time].map((preview) =>
          <NFLGameSuggestions preview={preview} onSelect={onSelect} />
        )}
      </div>
    </div>
  )
}