import React, { PropsWithChildren } from 'react';

import styles from './NavBarRoute.module.css';
import NavBar from '../components/ui/layout/NavBarComponent';
import Footer from '../components/ui/layout/FooterComponent';

export type NavBarRouteProps = { }

export default function NavBarRoute(props: PropsWithChildren<NavBarRouteProps>) {
  return (
    <div className={styles.container}>
      <NavBar />
      <div className={styles.content}>
        {props.children}
      </div>
      <Footer />
    </div>
  );
}