/* eslint-disable no-undef */
import { Query } from 'react-apollo';
import React, { useState } from 'react';

import styles from './HomeRoute.module.css';
import { GetNFLGamePreviewsQuery, GetNFLPreviewsQueryResponse } from '../queries/nfl/GetNFLPreviewsQuery';
import NFLGamePreviewsList from '../components/nfl/NFLGamePreviewListComponent';
import NavBarRoute from './NavBarRoute';
import { NFLGamePreview } from '../types/api/nfl/NFLGamePreview';
import NFLGamePreviewDetail from '../components/nfl/NFLGamePreviewDetailComponent';

export type HomeRouteProps = {

}

export default function HomeRoute() {
  const [selectedPreview, setSelectedPreview] = useState<NFLGamePreview | null>(null);

  const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const targetClassName = (e.target as Element).className;
    if (!targetClassName.startsWith("NFLGameSuggestion")) {
      setSelectedPreview(null);
    }
  }

  return (
    <NavBarRoute>
      <div className={styles.container}>
        <div className={styles.previews} onClick={handleBackgroundClick}>
          {renderNFLGamePreviews(setSelectedPreview)}
        </div>
        <div className={styles.details}>
          {renderPreviewDetail(selectedPreview)}
        </div>
      </div>
    </NavBarRoute>
  );
}

function renderNFLGamePreviews(onSelect: (preview: NFLGamePreview) => any): JSX.Element {
  const renderResponseWithOnSelect = (response: GetNFLPreviewsQueryResponse) => renderNFLGamePreviewsFromResponse(onSelect, response);
  return (
    <Query query={GetNFLGamePreviewsQuery}>
      {renderResponseWithOnSelect}
    </Query>
  );
}

function renderNFLGamePreviewsFromResponse(onSelect: (preview: NFLGamePreview) => any, response: GetNFLPreviewsQueryResponse): JSX.Element {
  const { error, data, loading } = response;
  if (error !== undefined) {
    return <p>There was an error.</p>;
  }
  if (loading) {
    return <p>Loading...</p>;
  }
  if (data !== undefined && data.getNFLGamePreviews !== undefined) {
    return <NFLGamePreviewsList previews={data.getNFLGamePreviews.previews} onSelect={onSelect}/>;
  }
  return <p>Please wait.</p>;
}

function renderPreviewDetail(preview: NFLGamePreview | null) {
  if (preview === null) {
    return <div></div>;
  }
  return (
    <div className={styles.previewDetailContainer}>
      <NFLGamePreviewDetail preview={preview} />
    </div>
  );
}